export const SET_USER_TOKEN = 'LOGIN_STATUS/SET_USER_TOKEN';
export const SET_REFRESH_TOKEN = 'LOGIN_STATUS/SET_REFRESH_TOKEN';
export const SET_USER_LOGGED_IN = 'LOGIN_STATUS/SET_USER_LOGGED_IN';

function generateToken(value, expiry) {
   return {
      value,
      expiry,
   };
}

export const setUserToken = ({ value, expiry }) => ({
   type: SET_USER_TOKEN,
   userToken: generateToken(value, expiry),
});

export const setRefreshToken = ({ value, expiry }) => ({
   type: SET_REFRESH_TOKEN,
   refreshToken: generateToken(value, expiry),
});

export const setUserLoggedIn = loggedIn => ({
   type: SET_USER_LOGGED_IN,
   loggedIn,
});

export default function reducer(
   state = {
      loggedIn: false,
      userToken: generateToken('', Date.now() + 60 * 60 * 1000),
      refreshToken: generateToken('', Date.now() + 60 * 60 * 1000),
   },
   action
) {
   switch (action.type) {
      case SET_USER_LOGGED_IN:
         return { ...state, loggedIn: action.loggedIn };
      case SET_USER_TOKEN:
         return { ...state, userToken: action.userToken };
      case SET_REFRESH_TOKEN:
         return { ...state, refreshToken: action.refreshToken };
      default:
         return state;
   }
}
