import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './assets/base.scss';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './config/configureStore';

const rootElement = document.getElementById('root');
const store = configureStore();

const renderApp = Component => {
   ReactDOM.render(
      <Provider store={store}>
         <Component />
      </Provider>,
      rootElement
   );
};

renderApp(App);

if (module.hot) {
   module.hot.accept('./App', () => {
      const NextApp = require('./App').default;
      renderApp(NextApp);
   });
}

serviceWorker.unregister();
