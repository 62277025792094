import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Suspense } from 'react';
import Loader from 'react-loaders';
import { lazy } from 'react';

const Login = lazy(() => import('./AynaPages/Login'));
const Main = lazy(() => import('./AynaPages/Main'));
const Error404 = lazy(() => import('./AynaPages/Errors/Error404'));

class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loggedIn: null,
      };
   }

   render() {
      return (
         <BrowserRouter>
            <Suspense
               fallback={
                  <div className='loader-container'>
                     <div className='loader-container-inner'>
                        <div className='text-center'>
                           <Loader type='ball-grid-beat' />
                        </div>
                        <h6 className='mt-3'>Please wait while we load the main app</h6>
                     </div>
                  </div>
               }>
               <Switch>
                  <Route exact path='/login'>
                     <Login />
                  </Route>
                  {this.props.loggedIn && (
                     <Route exact path='/'>
                        <HashRouter>
                           <Main />
                        </HashRouter>
                     </Route>
                  )}
                  {!this.props.loggedIn && (
                     <Route
                        exact
                        path='/'
                        render={routeProps => <Login next={routeProps.history.location} />}
                     />
                  )}
                  <Route>
                     <Error404 />
                  </Route>
               </Switch>
            </Suspense>
         </BrowserRouter>
      );
   }
}

const mapStateToProps = state => ({
   loggedIn: state.LoginStatus.loggedIn,
   userToken: state.LoginStatus.userToken,
   refreshToken: state.LoginStatus.refreshToken,
});

export default connect(mapStateToProps)(App);
